@import "../../assets/scss/core";

.notFound {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  > i {
    font-size: rem(170);
    line-height: 1.2;
    color: c(text);
  }
  > p {
    text-transform: uppercase;
    font-size: rem(20);
    font-weight: 100;
    margin: rem(10) 0 0;
    text-align: center;
    color: c(text, 0.6);
  }
  > a {
    font-size: rem(12);
    margin: rem(10) 0 0;
    padding: rem(10);
  }
}
