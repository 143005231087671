@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bbwp7u');
  src:  url('fonts/icomoon.eot?bbwp7u#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?bbwp7u') format('woff2'),
    url('fonts/icomoon.ttf?bbwp7u') format('truetype'),
    url('fonts/icomoon.woff?bbwp7u') format('woff'),
    url('fonts/icomoon.svg?bbwp7u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cv:before {
  content: "\e905";
}
.icon-sad:before {
  content: "\e904";
}
.icon-link:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-arrowBack:before {
  content: "\e903";
}
