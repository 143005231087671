@import "core.scss";
@import "reset.scss";
@import "ui-kit.scss";
@import "../fonts/icons/style.css";
@import "../fonts/site/stylesheet.css";

:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
}
html {
  font: 10px / 1.2 "Poppins", sans-serif;
  overflow-x: hidden;
  --padding: #{rem(60)};
  background: c(main);
  @include small-desktop {
    --padding: #{rem(40)};
    font-size: 9.5px;
  }
  @include mobile {
    --padding: #{rem(20)};
    font-size: 9px;
  }
}

body {
  font-size: rem(16);
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  color: c(text, 0.6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  position: relative;
}
.light {
  transition: transform 1.2s cubic-bezier(0.02, 1.23, 0.79, 1.08);
  position: fixed;
  pointer-events: none;
  z-index: -1;
  background: radial-gradient(
    circle at center,
    #{c(primary, 0.1)} 0,
    #{c(main)} 60%
  );
  width: rem(800);
  height: rem(800);
  top: rem(-400);
  left: rem(-400);
  will-change: transform;
  @include small-desktop {
    transform: none !important;
    top: rem(-300);
    left: rem(-300);
  }
}
.content {
  padding: 0 var(--padding);
  max-width: rem(1280);
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  gap: rem(20);
  @include mobile {
    gap: 0;
  }
  @include small-desktop {
    flex-direction: column;
  }
  header {
    top: 0;
    display: flex;
    padding: rem(60) 0 rem(20);
    flex-direction: column;
    justify-content: space-between;
    min-width: rem(400);
    @include mobile {
      padding: rem(40) 0 rem(0);
      min-width: auto;
    }
    @include desktop {
      height: 100vh;
      position: sticky;
      width: 40%;
    }
    .bottom {
      margin: rem(40) 0 0;
      display: flex;
      gap: rem(12);
      > a {
        background: c(text, 0.05);
        color: c(text);
        padding: rem(10);
        border-radius: rem(4);
        min-width: rem(40);
        height: rem(40);
        font-size: rem(12);
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        text-transform: uppercase;
        font-weight: 600;
        gap: rem(6);
        span {
          display: inline-block;
          margin: rem(2) 0 0;
        }
        i {
          font-size: rem(18);
        }
        @include hover {
          background: c(primary, 0.1);
        }
      }
    }
    .info {
      color: c(text);
      h1 {
        font-size: rem(50);
        line-height: 1.1;
        color: c(text, 0.7);
        margin: rem(-10) 0 0;
        font-weight: normal;
        b {
          font-weight: 600;
          color: c(text, 0.9);
        }
      }
      h2 {
        font-size: rem(20);
        font-weight: 200;
        margin: rem(18) 0 rem(8);
        opacity: 0.9;
      }
      > p {
        font-size: rem(14);
        opacity: 0.7;
        line-height: rem(24);
        @include desktop {
          max-width: rem(380);
        }
      }
    }
  }
  .right-side {
    width: 60%;
    @include small-desktop {
      width: 100%;
    }
  }
}

.navigation {
  font-size: rem(18);
  margin: rem(40) 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include small-desktop {
    display: none;
  }
  li {
    padding: rem(10) 0;
    cursor: pointer;
    color: c(text, 0.5);
    b {
      font-weight: 600;
      color: c(text);
    }
    &:before {
      content: "<";
    }
    &.active {
      pointer-events: none;
      color: c(text, 0.5);
      &:after {
        content: ' class="active">';
      }
    }
    &:after {
      content: " />";
    }
    @include hover {
      color: c(text, 0.8);
    }
  }
}
section {
  padding-top: rem(60);
  @include mobile {
    padding-top: rem(50);
  }
  &:last-child {
    padding-bottom: rem(24);
  }
  h3 {
    font-size: rem(18);
    color: c(text, 0.9);
    text-transform: uppercase;
    margin: 0 0 rem(20);
    @include mobile {
      font-size: rem(20);
    }
  }
  & + section {
    padding: rem(60) 0 rem(0);
    @include mobile {
      padding: rem(50) 0 rem(0);
    }
  }
}
#about {
  p {
    font-size: rem(14);
    color: c(text, 0.7);
    line-height: rem(24);
    b {
      font-weight: normal;
      color: c(text);
    }
  }
}
#experience {
  .list {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    padding: rem(8) 0 0;
    .item {
      gap: rem(20);
      display: flex;
      @include mobile {
        gap: rem(4);
        flex-direction: column;
      }
      .years {
        font-size: rem(12);
        text-transform: uppercase;
        width: rem(120);
      }
      .info {
        flex: 1;
      }
      h4 {
        font-size: rem(16);
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 rem(6);
        color: c(text);
      }
      .company {
        font-size: rem(12);
        opacity: 0.8;
        display: inline-block;
        margin: 0 0 rem(12);
      }
      .description {
        font-size: rem(13);
        line-height: rem(22);
      }
    }
  }
}
.skills {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.2;
  gap: rem(10);
  margin: rem(16) 0 0;
  li {
    padding: rem(6) rem(10);
    background: c(primary, 0.1);
    color: c(primary);
    font-size: rem(12);
    border-radius: rem(20);
  }
}
#portfolio {
  h3 a {
    background: c(primary, 0.1);
    font-size: rem(14);
    padding: rem(4) rem(8);
    border-radius: rem(20);
    margin: 0 rem(10);
    color: c(primary);
    font-weight: 600;
    line-height: rem(21);
    @include hover {
      background: c(primary, 0.2);
    }
    @include mobile {
      line-height: rem(24);
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    padding: rem(8) 0 0;
    .item {
      gap: rem(18);
      display: flex;
      align-items: flex-start;
      transition: opacity 400ms;
      opacity: 1;
      position: relative;
      .link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: transparent;
        font-size: 0;
        z-index: 4;
      }
      &:has(a) {
        @include hover {
          opacity: 0.7;
        }
      }
      @include mobile {
        gap: rem(10);
        flex-direction: row-reverse;
      }
      > img {
        width: rem(140);
        border-radius: rem(4);
        @include mobile {
          width: rem(120);
        }
      }
      .info {
        h4 {
          font-size: rem(16);
          font-weight: 600;
          text-transform: uppercase;
          margin: 0 0 rem(6);
          color: c(text);
        }
        .year {
          opacity: 0.8;
          margin: rem(8) 0 rem(16);
        }
        .description {
          font-size: rem(13);
          line-height: rem(22);
        }
      }
    }
  }
}
.singlePage {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: rem(60) 0;
  @include mobile {
    padding: rem(40) 0;
  }
  & {
    min-height: 100dvh;
  }
  min-height: 100vh;
  .description {
    font-size: rem(14);
    line-height: rem(24);
    margin: 0 0 rem(10);
  }
  .imagesBlock {
    display: grid;
    grid-template-columns: 1fr;
    margin: rem(20) 0 0;
    gap: rem(20);
    &.hasMobile {
      grid-template-columns: 1fr 0.4fr;
    }
    @include mobile {
      grid-template-columns: 1fr !important;
    }
    h3 {
      font-size: rem(20);
      text-transform: uppercase;
      color: c(text, 0.8);
    }
  }
  .mobile,
  .desktop {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    img {
      border-radius: rem(4);
      width: 100%;
    }
  }
  .mobile {
    max-width: rem(300);
    @include mobile {
      max-width: 100%;
      margin-top: rem(20);
    }
  }
  .desktop {
    flex: 1;
  }
  .backBlock {
    display: flex;
    gap: rem(20);
    align-items: center;
    margin: 0 0 rem(30);
    .back {
      font-size: rem(30);
      cursor: pointer;
      line-height: 1;
      @include hover {
        color: c(text);
      }
    }
    > h1 {
      font-size: rem(40);
      color: c(text, 0.8);
      text-transform: uppercase;
      @include mobile {
        font-size: rem(28);
      }
      > a {
        margin: 0 rem(10);
      }
      b {
        font-size: rem(14);
      }
    }
  }
}
